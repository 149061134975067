import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import SideNavBar from "./components/SideNavBar";
import AllProducts from "./pages/AllProducts";
import AddProduct from "./pages/AddProduct";

import AllCategories from "./pages/Categories/AllCategories";
import AllSubCategories from "./pages/Categories/AllSubCategories";
import AddCategory from "./pages/Categories/AddCategory";

import AddSubCategory from "./pages/Categories/AddSubCategory";

import Customers from "./pages/Customers/Customers";

import AllOrders from "./pages/Orders/AllOrders";
import Size from "./pages/Variations/Size";

import Brand from "./pages/Variations/Brand";
import Color from "./pages/Variations/Color";

import UpdateProduct from "./pages/Products/UpdateProduct";
import ListSize from "./pages/Variations/List/ListSize";
import ListBrand from "./pages/Variations/List/ListBrand";
import ListColor from "./pages/Variations/List/ListColor";

import UpdateOrder from "./pages/Orders/UpdateOrder";
import Sale from "./pages/Sale";
import OrderDetail from "./pages/Orders/OrderDetail";

import ImageBanner from "./pages/Banners/ImageBanner";
import ListImgBanner from "./pages/Banners/ListImgBanner";








import useAuth from "./auth/useAuth";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard/Dashboard";
import FailedOnline from "./pages/Orders/FailedOnline";
import SearchProducts from "./pages/SearchProducts";
import UpdatePassword from "./pages/Customers/UpdatePassword";

function HeaderComponent() {
  const { user } = useAuth();
  if (!user) {
    return <Login />;
  }

  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <SideNavBar />
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>       
          <Route path="/product/list" element={<AllProducts />}></Route>       
          <Route path="/product/create" element={<AddProduct />}></Route>
          <Route path="/product/update" element={<UpdateProduct />}></Route>    
          <Route path="/product/search" element={<SearchProducts />}></Route>    
          <Route path="/category/create-category" element={<AddCategory />} />
          <Route path="/category/sub-create" element={<AddSubCategory />} />
          <Route path="/category/list" element={<AllCategories />} />
          <Route path="/category/sublist" element={<AllSubCategories />} />  
          <Route path="/customer/list" element={<Customers />} />
          <Route path="/customer/updatepassword" element={<UpdatePassword />} />
          <Route path="/orders/failed-online" element={<FailedOnline />} />
          <Route path="/orders/list" element={<AllOrders />} />
          <Route path="/orders/update" element={<UpdateOrder />} />
          <Route path="/variations/size" element={<Size />} />
          <Route path="/list-variations/size" element={<ListSize />} />
          <Route path="/variations/brand" element={<Brand />} />
          <Route path="/list-variations/brand" element={<ListBrand />} />
          <Route path="/variations/color" element={<Color />} />
          <Route path="/list-variations/color" element={<ListColor />} />
          <Route path="/category/sale" element={<Sale />} />
          <Route path="/orders/detail" element={<OrderDetail />} />         
          <Route path="/banners/image" element={<ListImgBanner />} />
          <Route path="/banners/addImgBanner" element={<ImageBanner />} />
       
        
        </Routes>
      </div>
    </>
  );
}

export default HeaderComponent;
