import React from "react";
import { Link } from "react-router-dom";
const SideNavBar = () => {
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            {/* <Link className="nav-link active" to="/">
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt" />
              </div>
              Dashboard
            </Link> */}

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseProducts"
              aria-expanded="false"
              aria-controls="collapseProducts"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-box" />
              </div>
              Products
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseProducts"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/product/list">
                  All Products
                </Link>
                <Link className="nav-link sub_nav_link" to="/product/search">
                  Search Products
                </Link>
                <Link className="nav-link sub_nav_link" to="/product/create">
                  Add Product
                </Link>
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseCategories"
              aria-expanded="false"
              aria-controls="collapseCategories"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-list" />
              </div>
              Categories
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseCategories"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/category/list">
                  All Categories
                </Link>
                <Link className="nav-link sub_nav_link" to="/category/sublist">
                  All Sub-Categories
                </Link>
             
                <Link
                  className="nav-link sub_nav_link"
                  to="/category/create-category"
                >
                  Add Category
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/category/sub-create"
                >
                  Add Sub-Category
                </Link>
               
              </nav>
            </div>

          
            
           

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseOrders"
              aria-expanded="false"
              aria-controls="collapseOrders"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-credit-card" />
              </div>
              Orders
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseOrders"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/orders/list">
                  Order's
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/orders/failed-online">
                  Failed Transactions
                </Link>
              </nav>
              
            </div>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseVarList"
              aria-expanded="false"
              aria-controls="collapseVarList"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-credit-card" />
              </div>
              Variations
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseVarList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link
                  className="nav-link sub_nav_link"
                  to="/list-variations/size"
                >
                  Size
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/list-variations/brand"
                >
                  Brand
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/list-variations/color"
                >
                  Color
                </Link>
               
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseBannerList"
              aria-expanded="false"
              aria-controls="collapseBannerList"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-credit-card" />
              </div>
              Banners
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseBannerList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/banners/image">
                  Image
                </Link>
              </nav>
            </div>
            <Link className="nav-link" to="/customer/list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Customers
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SideNavBar;
