import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";

const OrderDetail = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const order = location.state;
  const date = new Date(order.updatedAt).toDateString();
  const time = new Date(order.updatedAt).toLocaleTimeString();

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="order">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Orders</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/orders/list")}
              >
                <i className="fas fa-arorder-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item ">Orders</li>
            <li className="breadcrumb-item active">Detail</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>Order Id: {order?._id}</h4>
              </div>
              <div className="card-title-2">
                <h4>Order Place On: {date}</h4>
              </div>
              <div className="card-title-2">
                <h4>Order Place Time: {time}</h4>
              </div>
              <div className="card-title-2">
                <h4>Order Status: {order?.deliveryStatus}</h4>
              </div>
              <div className="card-title-2">
                <h4>Delivery Slot: {order?.deliverySlot}</h4>
              </div>
              <div className="card-title-2">
                <h4>Payment Mode: {order?.paymentMethod}</h4>
              </div>
              <div className="card-title-2">
                <h4>Total Amount: QAR {order?.totalPrice}</h4>
              </div>

              <div className="card-title-2">
                <h4>
                  Customer: {order.user.name} ,({order.user._id})
                </h4>
              </div>
              <div className="card-title-2">
                <h4>
                  Shipping Address: {order.shippingAddress.address},
                  {order.shippingAddress.postalCode},
                  {order.shippingAddress.city}
                </h4>
              </div>
              <div className="card-title-2">
                <h4>
                  Contact Info: {order.shippingAddress.mobileNumber},
                  {order.shippingAddress.email}
                </h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Item Id</th>
                        <th>Item Name</th>
                        <th>Qty</th>
                        <th>Price (QAR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.orderItems.map((row, index) => {
                        console.log(row);
                        return (
                          <tr key={index}>
                            <td style={{ width: "160px" }}>{row._id}</td>
                            <td>{row.name}</td>
                            <td>{row.qty}</td>
                            <td>{row.price}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default OrderDetail;
