import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { mobile } from "../responsive";

import swal from "sweetalert";

import PropagateLoader from "react-spinners/PropagateLoader";
import styled from "styled-components";
import useAuth from "../auth/useAuth";
import apiClient from "../api/client";

const Login = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [uploadVisible, setUploadVisible] = useState(false);
  const navigate = useNavigate();
  const { logIn } = useAuth();

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (e) => {
    setUploadVisible(true);
    e.preventDefault();
    const result = await apiClient.post("/admin/adminlogin", {
      email: state.email,
      password: state.password,
    });
    if (result.ok) {
      setUploadVisible(false);

      swal("Login Success");
      logIn(result.data.token);
      //   navigate("/");
    } else {
      setUploadVisible(false);
      swal("Login Error Retry");
    }
  };

  return (
    <Container>
      {uploadVisible ? (
        <PropagateLoader color="rgba(54, 215, 183, 1)" size={30} />
      ) : (
        <Wrapper>
          <Title>SIGN IN</Title>
          <Form>
            <Input
              placeholder="Email Address"
              name="email"
              onChange={handleChange}
            />
            <Input
              placeholder="Password"
              name="password"
              onChange={handleChange}
              type="password"
            />
            <Button onClick={handleSubmit}>LOGIN</Button>
          </Form>
        </Wrapper>
      )}
    </Container>
  );
};
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #EDE4E3 ;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  /* top: 10%; */
`;

const Wrapper = styled.div`
  width: 25%;
  height: 50%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 20px;
`;

const Link = styled.a`
  /* margin: 25px 0px; */
  font-size: 17px;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;
  color: black;
`;

export default Login;
