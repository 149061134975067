import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import apiClient from "./../../api/client";
import { Pagination } from "@mui/material";
import swal from "sweetalert"


const FailedOnline = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [getList, setGetList] = useState([]);

  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
   
  }, [currentPageNo]);

  const fetchProducts = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/orders/online-failed", {pageNumber: currentPageNo});
 
    setGetList(data.orders);
    setPageCount(data.pageCount)
    setIsLoaded(false);
  };  
  const updatePayment = async (row) => {
  
    setIsLoaded(true);
    swal({
      title: "Are you sure?",
      text: "You want to update order to paid?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/orders/update-order-to-paid-admin", {
          id: row._id
        });
        if (result.ok) {
          
          swal("Sucess");
          setIsLoaded(false)
          window.location.reload()
        } else {
          swal("Error Retry!");
          setIsLoaded(false)
          window.location.reload()
        }
      } else {
        setIsLoaded(false)
          navigate("/orders/failed-online")
      }
    })
  };  



  return (
    <div id="layoutSidenav_content">
      <main>
        <div  className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Failed Online Transactions</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Orders</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Orders</h4>
              </div>
              <div  className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>#Order Id</th>
                        <th>#Order Placed Date</th>
                        <th>Status</th>
                        <th>Payment Status</th>
                        
                        <th>Total Amount</th>

                        <th>Info</th>
                        <th>Update</th>
                        <th>Update Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? getList?.map((row, index) => {
                            const date = new Date(row.updatedAt).toDateString();
                            const time = new Date(
                              row.updatedAt
                            ).toLocaleTimeString();

                            const status = row.deliveryStatus;

                            return (
                              <>
                                <tr key={index}>
                                  <td>{row._id}</td>
                                  <td>{date}</td>
                                  {status === "Processing" && (
                                    <td
                                      style={{
                                        backgroundColor: "yellow",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Delivered" && (
                                    <td
                                      style={{
                                        backgroundColor: "#50C878",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Cancelled" && (
                                    <td
                                      style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Out for Delivery" && (
                                    <td
                                      style={{
                                        backgroundColor: "#0096FF",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}

                                  <td>Failed</td>
                                 
                                  <td>{row?.totalPrice}</td>

                                  <td>
                                    <Link to="/orders/detail" state={row}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link>
                                  </td>
                                  <td className="action-btns">
                                    <Link to="/orders/update" state={row}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link>
                                  </td>
                                  <td>
                                  
                                      <Typography onClick={() => updatePayment(row)} className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
      </main>
    </div>
  );
};

export default FailedOnline;
