import React, { useState, useEffect } from "react";
import {
  Button,
  MenuItem,
  Select,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton,
} from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "./../api/client";
import { useNavigate } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "./AddProduct.css";

const intialState = {
  jobs: [{ name: "" }],
};
const intialState2 = {
  jobs: [{ nameAR: "" }],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const AddProduct = () => {
  const [file2, setFile2] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState(cat ? cat[0]?._id : "1");
  const [subcat, setSubCat] = useState();
  const [subId, setSubId] = useState(subcat ? subcat[0]?._id : "1");

  const [colors, setColors] = useState();
  const [colorId, setColorId] = useState();

  const [brands, setBrands] = useState();
  const [brandId, setBrandId] = useState("1");
  const [sizes, setSizes] = useState();
  const [sizeId, setSizeId] = useState();
  const [jobsData, setJobsData] = useState(intialState);
  const [jobsData2, setJobsData2] = useState(intialState2);

  const navigate = useNavigate();
  const classes = useStyles();

  const [state, setState] = useState({
    nameEN: "",
    nameAR: "",
    groupId: "",
    sku: "",
    image: "",
    descriptionEN: "",
    descriptionAR: "",
    detailsEN: [],
    detailsAR: [],
    cost_price: "",
    sell_price: "",
    discount: "",
    countInStock: "",
    notes: "",
    video: "",
  });

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
    fetchColors();
    fetchBrands();
    fetchSizes();
  }, []);

  const fetchColors = async () => {
    const { data } = await apiClient.get("/variations/getcolor");

    setColors(data);
  };

  const fetchBrands = async () => {
    const { data } = await apiClient.get("/variations/getbrand");
    setBrands(data);
  };

  const fetchSizes = async () => {
    const { data } = await apiClient.get("/variations/getsize");
    setSizes(data);
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCat(data);
  };

  const fetchSubCategories = async () => {
    const { data } = await apiClient.get("/variations/get-sub-categories");
    setSubCat(data);
  };

  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(file2.length < 1) {
      swal("Please select image")
    } else{
    swal({
      title: "Are you sure?",
      text: "You want to Add New Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        if (file2) {
          const formData = new FormData();

          for (const file of file2) {
            formData.append("image", file, file.name);
          }

          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          const { data } = await apiClient.post(
            "/uploadImages/uploadImages",
            formData,
            config
          );
       
          if (data) {
            const result1 = await apiClient.post("/products/create", {
              nameEN: state.nameEN,
              nameAR: state.nameAR,
              sku: state.sku,
              groupId: state.groupId,
              image: data,
              color: colorId,
              brand: brandId,
              category: catId,
              subcategory: subId,
              size: sizeId,
             
              cost_price: state.cost_price,
              sell_price: state.sell_price,
              discount: state.discount,
              countInStock: state.countInStock,
              notes: state.notes,
              descriptionEN: state.descriptionEN,
              descriptionAR: state.descriptionAR,
              detailsEN: jobsData.jobs,
              detailsAR: jobsData2.jobs,
              video: state.video,
            });
           
            if (result1.ok) {
              setState({
                nameEN: "",
                nameAR: "",
                groupId: "",
                sku: "",
                image: "",
                descriptionEN: "",
                descriptionAR: "",
                detailsEN: "",
                detailsAR: "",
                cost_price: "",
                sell_price: "",
                discount: "",
                countInStock: "",
                notes: "",
                video: "",
              });
              setFile2([]);
              swal("Success product added");
              navigate("/product/list");
            } else {
              swal("Product Upload failed due to network issue or missing fields")
            }
          } else {
            swal("Product upload Failed. Please Try again!!!!!");
              navigate("/product/list");
          }

        
        }
      }
    });}
  };

   const handleChange2 = (index, e) => {
    const values = [...jobsData.jobs];
    values[index][e.target.name] = e.target.value;
    setJobsData({ ...jobsData, jobs: values });
  };
  const handleChange3 = (index, e) => {
    const values = [...jobsData2.jobs];
    values[index][e.target.name] = e.target.value;
    setJobsData2({ ...jobsData2, jobs: values });
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setJobsData((prevState) => ({
      ...prevState,
      jobs: [...prevState.jobs, { name: "" }],
    }));
  };
  const handleAddField2 = (e) => {
    e.preventDefault();
    setJobsData2((prevState) => ({
      ...prevState,
      jobs: [...prevState.jobs, { nameAR: "" }],
    }));
  };

  const handleRemoveField = (index) => {
    const values = jobsData.jobs;
    values.splice(index, 1);
    setJobsData((prevState) => ({ ...prevState, values }));
  };
  const handleRemoveField2 = (index) => {
    const values = jobsData2.jobs;
    values.splice(index, 1);
    setJobsData2((prevState) => ({ ...prevState, values }));
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Products</a>
            </li>
            <li className="breadcrumb-item active">Add Product</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Product</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Name English*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            name="nameEN"
                            value={state.nameEN}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Name Arabic*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            name="nameAR"
                            value={state.nameAR}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product SKU*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product SKU"
                            name="sku"
                            value={state.sku}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Group Id*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Group Id"
                            name="groupId"
                            value={state.groupId}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Color*</label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={colorId}
                            label="Color"
                            onChange={(e) => setColorId(e.target.value)}
                          >
                            {colors?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.nameEN}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Brand*</label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={brandId}
                            label="Brand"
                            onChange={(e) => setBrandId(e.target.value)}
                          >
                            {brands?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.nameEN}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Size*</label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sizeId}
                            label="Size"
                            onChange={(e) => setSizeId(e.target.value)}
                          >
                            {sizes?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.nameEN}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Category*
                          </label>

                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catId}
                            label="Category"
                            onChange={(e) => setCatId(e.target.value)}
                          >
                            {cat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.nameEN}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Sub-Category*
                          </label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subId}
                            label="Sub-Category"
                            onChange={(e) => setSubId(e.target.value)}
                          >
                            {subcat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.nameEN}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">
                          Description English*
                        </label>
                        <input
                          style={{ overflow: "scroll" }}
                          type="text"
                          className="form-control"
                          placeholder="Product Description"
                          name="descriptionEN"
                          value={state.descriptionEN}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">
                          Description Arabic*
                        </label>
                        <input
                          style={{ overflow: "scroll" }}
                          type="text"
                          className="form-control"
                          placeholder="Product Description"
                          name="descriptionAR"
                          value={state.descriptionAR}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Video URL</label>
                        <input
                          style={{ overflow: "scroll" }}
                          type="text"
                          className="form-control"
                          placeholder="Video URL"
                          name="video"
                          value={state.video}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <div>
                          <TableContainer
                            component={Paper}
                            className="tb-container"
                          >
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Details English</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {jobsData?.jobs?.map((itemField, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="left">
                                      <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        type="text"
                                        name="name"
                                        onChange={(e) =>
                                          handleChange2(index, e)
                                        }
                                        value={itemField.name}
                                        placeholder="Detail Name in English"
                                      />
                                    </TableCell>

                                    <TableCell align="left">
                                      <IconButton
                                        onClick={() => handleRemoveField(index)}
                                      >
                                        <DeleteOutlinedIcon
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div className="addButton">
                            <button onClick={handleAddField}>+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <div>
                          <TableContainer
                            component={Paper}
                            className="tb-container"
                          >
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Details Arabic</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {jobsData2?.jobs?.map((itemField, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="left">
                                      <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        type="text"
                                        name="nameAR"
                                        onChange={(e) =>
                                          handleChange3(index, e)
                                        }
                                        value={itemField.name}
                                        placeholder="Detail Name in Arabic"
                                      />
                                    </TableCell>

                                    <TableCell align="left">
                                      <IconButton
                                        onClick={() =>
                                          handleRemoveField2(index)
                                        }
                                      >
                                        <DeleteOutlinedIcon
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div className="addButton">
                            <button onClick={handleAddField2}>+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Cost Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product C.P"
                            name="cost_price"
                            value={state.cost_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Sell Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product S.P"
                            name="sell_price"
                            value={state.sell_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Discount*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Disount"
                            name="discount"
                            value={state.discount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Count In Stock*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Stock"
                            name="countInStock"
                            value={state.countInStock}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Notes*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          value={state.notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Select images for the above product
                        </label>
                        <form>
                          <input
                            type="file"
                            multiple
                            onChange={uploadFileHandler}
                          />
                        </form>
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddProduct;
